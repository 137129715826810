const FIELD_NAMES = {
    "PregnancyInterview": "Wywiad porodu",
    "Injury": "Przebyte urazy/operacje",
    "Disease": "Choroby towarzyszące",
    "EmotionalAspect": "Aspekt emocjonalny",
    "Treatment": "Dotychczasowe leczenie",
    "ICD9": "Kody ICD-9",
    "ICD10": "Kody ICD-10",
    "ICF": "Kody ICF",
    "InterviewField": "Wywiad",
    "FunctionalTest": "Badania funkcjonalne",
    "ChildAssessmentSwitch": "Ocena rozwoju dziecka do 2 lat",
    "InfantBackDrawing": "Niemowlak na plecach",
    "InfantFrontDrawing": "Niemowlak na brzuchu",
    "AssessmentNotes": "Notatki",
    "ChildActivityAssessment": "Ocena spontanicznej aktywności dziecka w wieku od 0 do 24 miesiąca życia",
    "ChildOverallAssessment": "Ocena dziecka",
    "ChildDrawing": "Rysunek dziecka",
    "Coordination": "Koordynacja",
    "WalkPattern": "Wzorzec chodu",
    "NeuroReflexDisorders": "Odruchy i zaburzenia neurologiczne",
    "Notes": "Notatki własne",
    "Plan": "Plan fizjoterapii",
    "Therapy": "Terapia",
    "Recommendation": "Zalecenia",
    "IssuedDocs": "Wydane opinie/orzeczenia/zaświadczenia",
    "Medicaments": "Zlecone wyroby medyczne"
}

export default FIELD_NAMES;