<template>
  <PatientCardWrapper
    :session.sync="session"
    :owner="owner"
    :hideCloneShfs="hideCloneShfs"
    :loading="loading"
    @clone="onClone"
    @change="onChange"
    @new="onNew"
    @uploaded="onUploaded"
  >
    <v-layout slot="shfs" class="full-width">
      <v-row no-gutters style="width: 100%">
        <v-col
          v-for="(shf, name, index) in session.shfs"
          :key="shf.name"
          :cols="customProps.shfs[index].cols"
          class="px-6 py-3"
        >
          <FieldWrapperDelegate
            :field.sync="session.shfs[name]"
            :session.sync="session"
            :patient="patient"
            :owner="owner"
            :disabled="!session.active"
            :name="customProps.shfs[index].label"
            @predefined="onPredefined"
            @append="onAppend"
            @copy="onCopy"
            @changed:codes="onChangedCodes"
            hideCollapseBtn
            sessionField
            autosave
          ></FieldWrapperDelegate>
        </v-col>
      </v-row>
    </v-layout>
    <v-layout slot="ahfs" class="full-width">
      <v-row no-gutters style="width: 100%">
        <v-col
          v-for="(ahfs, name, index) in filteredAhfs"
          :key="name"
          :cols="customProps.ahfs[index].cols"
          v-show="session.ahfs[name].length > 0"
        >
          <HistoryFieldWrapperDelegate
            class="fill-height"
            :fields.sync="session.ahfs[name]"
            :session.sync="session"
            :patient="patient"
            :owner="owner"
            :color="customProps.ahfs[index].color"
            :name="
              customProps.ahfs[index].label ||
              (session.ahfs[name][0] && session.ahfs[name][0].label)
            "
            @predefined="onPredefined"
            @append="onAppend"
            @changed:codes="onChangedCodes"
            @copy="onCopy"
            autosave
          ></HistoryFieldWrapperDelegate>
        </v-col>
      </v-row>
    </v-layout>
  </PatientCardWrapper>
</template>

<script>
import FIELD_NAMES from "@/constants/patientcard/pediatric";
import PatientCardMixin from "@/mixins/patientcard/PatientCardMixin";

export default {
  mixins: [PatientCardMixin],
  data() {
    return {
      names: FIELD_NAMES,
    };
  },
  computed: {
    ahfsKeys() {
      return Object.keys(this.session.ahfs);
    },
    visibilityCondition() {
      return (name) => {
        if (
          [
            "InfantBackDrawing",
            "InfantFrontDrawing",
            "AssessmentNotes",
            "ChildActivityAssessment",
          ].includes(name) &&
          this.ahfsKeys.includes("ChildAssessmentSwitch") &&
          this.session.ahfs["ChildAssessmentSwitch"].length > 0 &&
          this.session.ahfs["ChildAssessmentSwitch"][0].data
        ) {
          return this.session.ahfs["ChildAssessmentSwitch"][0].data.value;
        } else {
          return true;
        }
      };
    },
    filteredAhfs() {
      if (!this.session.ahfs) {
        return {};
      }
      
      let ahfs = {};
      for (let [key, value] of Object.entries(this.session.ahfs)) {
        if (this.visibilityCondition(key)) {
          ahfs[key] = value;
        }
      }
      return ahfs;
    },
  },
  components: {
    PatientCardWrapper: () =>
      import("@/components/cards/patientcard/PatientCardWrapper"),
    FieldWrapperDelegate: () =>
      import("@/components/delegates/patientcard/FieldWrapperDelegate"),
    HistoryFieldWrapperDelegate: () =>
      import("@/components/delegates/patientcard/HistoryFieldWrapperDelegate"),
  },
  methods: {
    onPredefined(data) {
      this.$emit("predefined", data);
    },
    onClone(group) {
      this.$emit("clone", group);
    },
    onChange(options) {
      this.$emit("change", options);
    },
    onNew() {
      this.$emit("new");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onUploaded(value) {
      this.$emit("uploaded", value);
    },
    onCopy() {
      this.$emit("copy");
    }
  },
};
</script>